import { useIsMobile } from '@/common/contexts/IsMobileContext';
import { useDeviceClasses } from '@/common/hooks/useDeviceClasses';
import { useLocale } from '@/common/hooks/useLocale';
import Link from 'next/link';
import type { FC } from 'react';
import Copyright from './FooterCopyright';
import type { FooterProps, LeftNavProps } from './footer';

function LeftNavItem({ title, type, link, fontWeight }: LeftNavProps) {
  const containerClassName = useDeviceClasses({
    mobile: 'text-body12-l-v',
    desktop: 'min-w-[56px] text-button text-body10-l',
    common: 'text-grayscale-1'
  });

  if (type === 'window')
    return (
      <button
        type="button"
        onClick={() => window.open(link, '_blank', 'width=800,height=840')}
        tabIndex={0}
        className={containerClassName}
        style={{ fontWeight }}
      >
        {title}
      </button>
    );
  if (type === 'link')
    return (
      <Link
        aria-label={`move to ${link}`}
        href={link || '#'}
        className={containerClassName}
        style={{ fontWeight }}
      >
        {title}
      </Link>
    );
}
function Container({ leftNav }: FooterProps) {
  const isMobile = useIsMobile();
  return (
    <div
      className={useDeviceClasses({
        mobile: 'space-x-20v',
        desktop: 'space-x-36 h-30',
        common: 'flex items-center'
      })}
    >
      {leftNav!.map((nav) => {
        if (isMobile && nav.link === '/proposal') {
          return null;
        }
        return (
          <LeftNavItem
            key={nav.title}
            title={nav.title}
            type={nav.type}
            link={nav.link}
            fontWeight={nav.fontWeight}
          />
        );
      })}
    </div>
  );
}

const FooterLeftNav: FC<FooterProps> = ({ leftNav }) => {
  const { isKo } = useLocale();

  const style = {
    wrapper: useDeviceClasses({
      mobile: `mb-40v space-y-16v`,
      desktop: 'space-y-32',
      common: ''
    })
  };

  return (
    // 영어일 때 -> 간격 맞춰주기

    <div className={style.wrapper}>
      {isKo && leftNav && <Container leftNav={leftNav} />}
      <Copyright />
    </div>
  );
};

export default FooterLeftNav;
