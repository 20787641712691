import locales from '@/common/constants/locale';
import { useDeviceClasses } from '@/common/hooks/useDeviceClasses';
import { useLocale } from '@/common/hooks/useLocale';
import { useOutsideClick } from '@/common/hooks/useOutsideClick';
import LocalLogo from '@/common/icons/LocalLogo';
import { merge } from '@/styles/tailwind/src';
import { useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';
import { twJoin } from 'tailwind-merge';

const localList = [
  {
    label: '한국어',
    local: locales.korea
  },
  { label: 'English', local: locales.english },
  { label: '日本語', local: locales.japan }
];

interface LanguageItemProps {
  label: string;
  local: string;
  currentLanguage: string;
  onSelect: (local: string) => void;
}
const LanguageItem = ({ label, local, currentLanguage, onSelect }: LanguageItemProps) => {
  const textStyle = useDeviceClasses({
    mobile: twJoin(currentLanguage === local ? 'text-body12-l-v' : 'text-body12-m-v'),
    desktop: 'text-body11-m',
    common: twJoin(local === locales.japan ? 'font-pretendardJP' : '')
  });
  const itemStyle = useDeviceClasses({
    common: 'bg-white',
    desktop: 'flex py-14 h-48 pl-16 pr-24',
    mobile: 'w-100v pl-16v py-13v text-left'
  });

  const activeStyle = currentLanguage === local ? 'text-grayscale-1 bg-grayscale-11' : '';

  return (
    <li role="menuitem">
      <button
        type="button"
        key={label}
        className={merge(
          ' w-full text-grayscale-5 transition-all duration-150 hover:bg-grayscale-10 hover:text-grayscale-3',
          activeStyle,
          itemStyle
        )}
        disabled={local === currentLanguage}
        onClick={() => onSelect(local)}
      >
        <span className={textStyle}>{label}</span>
      </button>
    </li>
  );
};

interface LanguageSwitcherProps {
  fillType: string;
}

function LanguageSwitcher({ fillType }: LanguageSwitcherProps) {
  const router = useRouter();
  const [modalMenuRef, isActive, setIsClicked, setIsActive] = useOutsideClick(false);
  const { switchLanguage, currentLanguage } = useLocale();
  const queryClient = useQueryClient();

  const vislbleStyleDesktop = isActive
    ? 'min-h-104 opactiy-100 visible'
    : 'min-h-0 opacity-0 invisible';

  const vislbleStyleMobile = isActive
    ? 'min-h-40v opactiy-100 visible'
    : 'min-h-0 opacity-0 invisible';

  const switchLanguageWithApiRefetch = useCallback(
    (lng: string) => {
      switchLanguage(lng);
      queryClient.invalidateQueries();
      setIsActive(false);
    },
    [switchLanguage, queryClient]
  );

  useEffect(() => {
    if (isActive) setIsActive(false);
  }, [router.asPath]);

  return (
    <div
      className={useDeviceClasses({
        common: 'relative',
        desktop: 'absolute right-70'
      })}
      ref={modalMenuRef}
    >
      <button
        type="button"
        aria-label="local chose button"
        aria-haspopup="true"
        aria-expanded={isActive}
        onClick={() => setIsClicked()}
        className={useDeviceClasses({
          desktop: 'ml-auto flex h-40 w-40  justify-center',
          mobile: 'h-20v w-20v'
        })}
      >
        <LocalLogo
          className={useDeviceClasses({ mobile: 'h-20v w-20v', common: 'cursor-pointer' })}
          fill={fillType}
        />
      </button>
      <ul
        role="menu"
        className={useDeviceClasses({
          common: merge(
            'selectBox absolute z-[120] flex flex-col  bg-white  shadow-mobileLocaleSelectBox transition-all duration-300 '
          ),
          desktop: merge('right-0 top-32 w-100 rounded-4 py-4 ', vislbleStyleDesktop),
          mobile: merge('-right-38v top-32v w-100v rounded-4v py-4v', vislbleStyleMobile)
        })}
      >
        {localList.map(({ label, local }) => {
          return (
            <LanguageItem
              key={local}
              label={label}
              local={local}
              currentLanguage={currentLanguage}
              onSelect={switchLanguageWithApiRefetch}
            />
          );
        })}
      </ul>
    </div>
  );
}
export default LanguageSwitcher;
