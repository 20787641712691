import { useDeviceClasses } from '@/common/hooks/useDeviceClasses';
import { useLayoutEffect } from '@/common/hooks/useLayoutEffect';
import { useLocale } from '@/common/hooks/useLocale';
import { useScroll } from '@/common/hooks/useScroll';
import { useWheelDirection } from '@/common/hooks/useWheelDirection';
import { helper } from '@/common/utils';
import { merge } from '@/styles/tailwind/src';
import { CSSProperties, useCallback, useMemo, useState } from 'react';
import { twJoin } from 'tailwind-merge';

interface IuseStyles {
  hover: boolean;
  asPath: string;
}
const HEADER_HIDE_POSSBLE_SCROLL_Y = 50;
export default function useStyles({ hover, asPath }: IuseStyles) {
  const { isKo } = useLocale();
  const isHome = asPath === '/';
  const { y: scrollY } = useScroll();
  const { wheelDirection } = useWheelDirection();
  const { getMatchStyles } = helper;
  const [duration, setDuration] = useState('0ms');
  const isHeaderVisible = wheelDirection === 'up' && isHome && scrollY > 5;
  const isScrollOverWheelDown = wheelDirection === 'down' && scrollY > 5;
  const desktopHeight = getMatchStyles(isKo, 'h-310', 'h-386');

  const isActiveTernaryOperator = useCallback(
    (condition1: string, condition2: string) => {
      return isHeaderVisible ? condition1 : condition2;
    },
    [isHeaderVisible]
  );

  const home = useMemo(() => {
    return {
      logo: isActiveTernaryOperator('black', 'white'),
      header: isActiveTernaryOperator('bg-white', 'bg-transparent'),
      language: isActiveTernaryOperator('black', 'white')
    };
  }, [isHeaderVisible]);

  const notHome = { logo: 'black', header: 'bg-white', language: 'black' };

  const logo = getMatchStyles(isHome, home.logo, 'black');

  const languageFill = getMatchStyles(isHome && !hover, home.language, 'black');

  const events = { isHover: getMatchStyles(isHome && isHeaderVisible, true, hover) };

  const headerContainer = useDeviceClasses({
    mobile: merge(
      'z-30 fixed duration-150 fixed',
      getMatchStyles(isHome && scrollY < HEADER_HIDE_POSSBLE_SCROLL_Y, 'bg-transparent', 'bg-white')
    ),
    common: merge('w-screen z-[130] text-white items-center mx-auto text-body'),
    desktop: merge(
      'min-h-80 flex justify-center items-center pt-27 text-body-10-l px-80 min-w-1360',
      getMatchStyles(
        isHome,
        twJoin(
          home.header,
          getMatchStyles(isScrollOverWheelDown, 'opacity-0 pointer-events-none'),
          getMatchStyles(isHeaderVisible, 'shadow-light-2-2 opacity-1')
        ),
        notHome.header
      ),
      getMatchStyles(hover, `${desktopHeight} bg-white shadow-light-2-2`, 'h-80')
    )
  });

  const headerInlineStyle: CSSProperties = {
    position: 'fixed',
    transitionDuration: duration,
    transitionProperty: 'height, background, opacity'
  };

  useLayoutEffect(() => {
    let timeout: NodeJS.Timeout | null = null;
    if (!isHome) {
      setDuration('0ms');
    } else {
      timeout = setTimeout(() => {
        setDuration('200ms');
      }, 300);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [isHome]);

  const styles = {
    headerContainer,
    logo,
    languageFill,
    events,
    headerInlineStyle
  };

  return styles;
}
